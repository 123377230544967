
import { Component, Prop } from 'nuxt-property-decorator'
import { HomeOffer, createTranslatedOfferBenefitsInstance, createTranslatedOfferDetailsInstance, TranslatableContext, createAmpTranslatedOfferDetailsInstance } from '@alao-frontend/core'
import OfferCardLayout from '~/components/offers/OfferCard/OfferCardLayout.vue'
import ExpertTip from '~/components/offers/OfferCard/ExpertTip'
import OfferCardDetails from '~/components/offers/OfferCard/OfferCardDetails'
import OfferPromoBanners from '~/components/offers/OfferPromoBanners'
import OfferDynamicBanner from '~/components/banners/OfferDynamicBanner'
import OfferCardPrice from '~/components/offers/OfferCard/OfferCardPrice.vue'
import { OfferCardMixin } from '~/components/offers/OfferCard/mixins/offer-card.mixin'
import { checkDateLessDays } from '~/utils/check-date-less-days'
import { FeatureFlags } from '~/core/config/feature-flags'

@Component({
  components: {
    OfferCardLayout,
    ExpertTip,
    OfferCardDetails,
    OfferPromoBanners,
    OfferDynamicBanner,
    OfferCardPrice,
  },
})
export default class HomeOfferCard extends OfferCardMixin {
  @Prop({ required: true, default: () => ({}), type: Object })
  protected readonly offer!: HomeOffer

  protected readonly details = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)
    ? createAmpTranslatedOfferDetailsInstance(
      this.offer,
      this.$i18n as TranslatableContext,
    )
    : createTranslatedOfferDetailsInstance(
      this.offer,
      this.$i18n as TranslatableContext,
    )

  protected readonly benefits = createTranslatedOfferBenefitsInstance(
    this.offer,
    this.$i18n as TranslatableContext)

  protected checkDateLessDays = checkDateLessDays
  get endDateInFewDays () {
    if (!this.offer.endDate) {
      return this.offer.endDate
    }

    return checkDateLessDays(this.offer.endDate) ? this.offer.endDate : null
  }
}
