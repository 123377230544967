
import { Component, Prop } from 'nuxt-property-decorator'
import { BundleOffer, createTranslatedOfferBenefitsInstance, createTranslatedOfferDetailsInstance, TranslatableContext, createAmpTranslatedOfferDetailsInstance } from '@alao-frontend/core'
import OfferCardLayout from '~/components/offers/OfferCard/OfferCardLayout.vue'
import ExpertTip from '~/components/offers/OfferCard/ExpertTip'
import OfferCardDetails from '~/components/offers/OfferCard/OfferCardDetails'
import OfferCardPrice from '~/components/offers/OfferCard/OfferCardPrice.vue'
import BundleOfferCardFooter from '~/components/offers/OfferCard/BundleOfferCard/BundleOfferCardFooter.vue'
import OfferOptions from '~/components/offers/OfferOptions'
import { OfferCardMixin } from '~/components/offers/OfferCard/mixins/offer-card.mixin'
import { FeatureFlags } from '~/core/config/feature-flags'

@Component({
  components: {
    OfferCardLayout,
    ExpertTip,
    OfferCardDetails,
    OfferCardPrice,
    BundleOfferCardFooter,
    OfferOptions,
  },
})
export default class BundleOfferCard extends OfferCardMixin {
  @Prop({ required: true, default: () => ({}), type: Object })
  protected readonly offer!: BundleOffer

  protected readonly details = this.$featureFlags.isEnabled(FeatureFlags.AMP_COMPARATOR)
    ? createAmpTranslatedOfferDetailsInstance(
      this.offer,
      this.$i18n as TranslatableContext,
    )
    : createTranslatedOfferDetailsInstance(
      this.offer,
      this.$i18n as TranslatableContext,
    )

  protected readonly benefits = createTranslatedOfferBenefitsInstance(
    this.offer,
    this.$i18n as TranslatableContext)
}
